import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    ProducerFilter,
    ProducerSortFilter,
    ProducerFilterInfo,
    Switcher,
    ProducersMap
} from 'lition-shared-components';

import {
    KEYBOARD_KEY_VALUES,
    PRODUCER_ORDER_BY_OPTIONS,
    DEFAULT_ORDER_BY,
    ENERGY_TYPES,
    PRODUCER_FILTER_OPTIONS,
    PAGE_ID,
    DEFAULT_SORT_DIRECTION,
    SORT_DIRECTION,
    DEFAULT_ZIP,
    DEFAULT_ENERGY_TYPE,
    DEFAULT_PAGE_ID,
    DEFAULT_FILTER_BY,
    DEFAULT_CONSUMPTION
} from '../../constants';

import { richTextToHtml } from '../../services/helpers/contentful';
import ProducersPageHelpers from '../../services/helpers/page/producers';
import CommonPageHelpers from '../../services/helpers/page/common';
import { constructProducersUrl, constructPageURLParams } from '../../services/helpers/url/urlBuilder';
import { AppContext } from '../../services/helpers/context';
import { filterProducersByType, getFilterProducerTypesByQuery } from '../../services/helpers/producers';
import * as GTM from '../../services/helpers/gtm';
import { bindMethods } from '../../services/helpers/utils';

import MainContainer from '../../components/__organisms__/MainContainer';
import Accordion from '../../components/__organisms__/Accordion';
import TariffCalculatorPopup from '../../components/__organisms__/TariffCalculatorPopup';
import { Settings, Triangle } from '../../components/__icons__';
import { scrollToTopIfIframe } from '../../components/__mixins__/handlers';
import * as mixinHandlers from '../../components/__mixins__/handlers';
import * as mixinRenderComponents from '../../components/__mixins__/renderComponents';
import { pickQueryParamsWithDefaults } from '../../services/helpers/url/url';

import './index.css';

const FILTER_CAPTION = 'Energiequellen:';

// TODO: Need to refactoring isEcoenergy logic @Anton
// TODO: We need to remove the filter logic from api response and to use the logic in the filterProducersByType. @Anton
// TODO: Also we should check the all places where we use the isEcoenergy prop. @Anton

class ProducersPage extends PureComponent {
    static contextType = AppContext;

    static openProducerTariff(producer) {
        if (window.self !== window.top) {
            window.parent.postMessage({ tariffDetails: producer }, '*');
        }
        // eslint-disable-next-line react/no-unused-state
        this.setState({ isProducerTariffModalOpen: true, activeProducerData: producer });
        scrollToTopIfIframe();
    }

    static closeProducerTariff() {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ isProducerTariffModalOpen: false, activeProducerData: null });
    }

    constructor(props) {
        super(props);

        const { query = {}, query: { identifier = DEFAULT_PAGE_ID } = {} } = props;

        this.state = {
            selectedProducerTypes: getFilterProducerTypesByQuery(query),
            isMapOpen: identifier === PAGE_ID.REGIONAL_CAMPAIGN,
            tariffCalculatorPopupIsOpen: false,
            // eslint-disable-next-line react/no-unused-state
            isProducerTariffModalOpen: false,
            // FIXME: Looks redundant to have these 2 (activeProducerIdб activeProducerData) state values, activeProducerData is enough @Anton
            activeProducerId: null,
            // eslint-disable-next-line react/no-unused-state
            activeProducerData: null,

            // FIXME: Maybe we should add getContentErrorDetails and getDataErrorDetails in future to handle accordingly
            // eslint-disable-next-line react/no-unused-state
            compareProductPostcodeErrorMessage: props.compareProductPostcodeErrorMessage,
            // eslint-disable-next-line react/no-unused-state
            consumptionNoTariffErrorMessage: props.consumptionNoTariffErrorMessage,
            compareProductServerErrorDetails: props.compareProductServerErrorDetails, // Use it for show specific calculator error in specific places
            // eslint-disable-next-line react/no-unused-state
            commonServerErrorDetails: props.commonServerErrorDetails // Use it for show common error in the toast popup
        };

        this.bindMethods = bindMethods.bind(this);
        this.bindMethods(mixinHandlers);
        this.bindMethods(mixinRenderComponents);

        this.openProducerTariff = ProducersPage.openProducerTariff.bind(this);
        this.closeProducerTariff = ProducersPage.closeProducerTariff.bind(this);
        this.handleFlip = ProducersPage.handleFlip.bind(this);

        this.changeAscDesc = this.changeAscDesc.bind(this);
        this.onProducerTypeChange = this.onProducerTypeChange.bind(this);
        this.selectOrderByOption = this.selectOrderByOption.bind(this);
        this.renderMapSwitcher = this.renderMapSwitcher.bind(this);
        this.renderProducerFilter = this.renderProducerFilter.bind(this);
        this.renderProducerSortFilter = this.renderProducerSortFilter.bind(this);
        this.renderPageContent = this.renderPageContent.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
    }

    componentDidMount() {
        const {
            query: { energyType = DEFAULT_ENERGY_TYPE },
            content: { landingDetails } = {},
            producers
        } = this.props;

        GTM.addProductListDetailsToDL(producers, landingDetails, energyType);

        if (window.self !== window.top) {
            window.parent.postMessage({ gtm: { impression: producers } }, '*');
            const resizeObserver = new ResizeObserver(() => {
                if (!Number.isNaN(document.body.scrollHeight)) {
                    window.parent.postMessage({ size: document.body.scrollHeight }, '*');
                }
            });
            resizeObserver.observe(document.body);
        }

        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }

    // The method also uses for contract power plant tariffs (pages/partners/contract/index.js)
    static handleFlip(id) {
        const { activeProducerId } = this.state;
        if (activeProducerId === id) {
            this.setState({ activeProducerId: undefined });
        } else {
            this.setState({ activeProducerId: id });
        }
    }

    handleMessage(event) {
        if (typeof event.data === 'object') {
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(event.data)) {
                switch (key) {
                    case 'size':
                        document.documentElement.style.setProperty('--frame-height', `${value + 32}px`);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    onProducerTypeChange(type) {
        const { selectedProducerTypes } = this.state;
        const { query } = this.props;

        const isExistingType = selectedProducerTypes.includes(type);
        const isAllSelectedTypes = selectedProducerTypes.length === PRODUCER_FILTER_OPTIONS.length;
        const isTypeForRemove = isExistingType && !isAllSelectedTypes && selectedProducerTypes.length !== 1;

        let updatedSelectedProducerTypes = selectedProducerTypes;

        if (!isExistingType) {
            updatedSelectedProducerTypes = [...selectedProducerTypes, type];
        }

        if (isTypeForRemove) {
            updatedSelectedProducerTypes = selectedProducerTypes.filter(producerType => producerType !== type);
        }

        if (isAllSelectedTypes) {
            updatedSelectedProducerTypes = [type];
        }

        this.setState({ selectedProducerTypes: updatedSelectedProducerTypes });

        const filterBy = updatedSelectedProducerTypes.join();
        const url = constructProducersUrl({ ...query, filterBy });
        if (window.self !== window.top) {
            window.parent.postMessage({ url }, '*');
        }
        // TODO: Use next/router for this @Anton
        window.history.replaceState(null, '', url);
    }

    // TODO: Use loadScript from service and cover by tests (R)
    addScript(src) {
        const script = document.createElement('script');

        script.src = src;
        script.async = true;
        script.type = 'text/javascript';

        document.body.appendChild(script);
    }

    selectOrderByOption(option) {
        const { query, isEcoenergy } = this.props;

        const newParams = {
            ...query,
            orderBy: option,
            isEcoenergy
        };

        GTM.addSortFilterClickDataToDL({ label: option });
        if (window.self !== window.top) {
            window.parent.postMessage({ url: constructProducersUrl(newParams) }, '*');
        }
        window.location.assign(constructPageURLParams(newParams));
    }

    changeAscDesc() {
        const {
            query,
            query: { ascDesc = DEFAULT_SORT_DIRECTION },
            isEcoenergy
        } = this.props;

        const newParams = {
            ...query,
            isEcoenergy,
            ascDesc: ascDesc.toUpperCase() === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
        };
        if (window.self !== window.top) {
            window.parent.postMessage({ url: constructProducersUrl(newParams) }, '*');
        }
        window.location.assign(constructPageURLParams(newParams));
    }

    renderOrderList() {
        const {
            query: { orderBy = DEFAULT_ORDER_BY }
        } = this.props;

        return (
            <ul className="ltn-de-producers-order-by-option-list">
                {Object.keys(PRODUCER_ORDER_BY_OPTIONS).map(optionKey => (
                    <li
                        key={optionKey}
                        className={classNames(
                            'ltn-de-producers-order-by-option-element',
                            optionKey === orderBy && 'ltn-de-producers-order-by-option-selected'
                        )}
                    >
                        <button type="button" onClick={() => this.selectOrderByOption(optionKey)}>
                            {PRODUCER_ORDER_BY_OPTIONS[optionKey]}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    renderAscDescArrow() {
        const {
            content: { pageContent },
            query: { ascDesc = DEFAULT_SORT_DIRECTION }
        } = this.props;

        return (
            <button className="ltn-de-producers-asc-desc-wrapper" type="button" onClick={this.changeAscDesc}>
                <span className={ascDesc === SORT_DIRECTION.ASC ? 'ltn-de-producers-asc-desc-active' : ''}>
                    {pageContent.orderAscLabel}
                </span>
                <span>&nbsp; /&nbsp; </span>
                <span className={ascDesc === SORT_DIRECTION.DESC ? 'ltn-de-producers-asc-desc-active' : ''}>
                    {pageContent.orderDescLabel}
                </span>
                &nbsp;
                <div className="ltn-de-producers-asc-desc-wrapper-triangles">
                    <div className="ltn-de-producers-triangle-up" />
                    <div className="ltn-de-producers-triangle-down" />
                </div>
            </button>
        );
    }

    renderMapSwitcher() {
        const { isMapOpen } = this.state;

        return (
            <Switcher
                title="Karte anzeigen"
                onToggled={() => {
                    if (!isMapOpen) {
                        GTM.addShowMapClickDataToDL();
                    }
                    this.setState({ isMapOpen: !isMapOpen });
                }}
                active={isMapOpen}
            />
        );
    }

    renderProducerFilter() {
        const {
            content: { isVersion2Mode }
        } = this.props;
        const { selectedProducerTypes } = this.state;

        return (
            <ProducerFilter
                className={isVersion2Mode ? 'ltn-producer-filter-version2' : ''}
                onTypeChange={this.onProducerTypeChange}
                selectedProducerTypes={selectedProducerTypes}
                // TODO: Get the options and caption from producer config
                options={PRODUCER_FILTER_OPTIONS}
                caption={FILTER_CAPTION}
            />
        );
    }

    renderProducerSortFilter({ className, labels, withAscDescOptions, orderButtonIcon, orderByOptions }) {
        const {
            query: { orderBy = DEFAULT_ORDER_BY, ascDesc = DEFAULT_SORT_DIRECTION }
        } = this.props;

        return (
            <ProducerSortFilter
                className={className}
                labels={labels}
                orderBy={orderBy}
                withAscDescOptions={withAscDescOptions}
                orderButtonIcon={orderButtonIcon}
                orderByOptions={orderByOptions}
                ascDesc={ascDesc}
                onClick={this.selectOrderByOption}
                onChangeAscDesc={this.changeAscDesc}
            />
        );
    }

    // TODO: We need to refactor the filter part. @Anton
    renderFilterSection() {
        const {
            content: { pageContent, isVersion2Mode },
            query: {
                orderBy = DEFAULT_ORDER_BY,
                zip = DEFAULT_ZIP,
                consumption = DEFAULT_CONSUMPTION,
                energyType = DEFAULT_ENERGY_TYPE
            }
        } = this.props;

        const mobileFilterV2 = (
            <div className="ltn-de-producers-page-controls-v2-mobile">
                {this.renderMapSwitcher()}
                <button
                    className="ltn-de-producers-page-popup-trigger-button"
                    type="button"
                    onClick={() => this.setState({ tariffCalculatorPopupIsOpen: true })}
                    onKeyDown={e => e.key === KEYBOARD_KEY_VALUES.ENTER && this.setState({ tariffCalculatorPopupIsOpen: true })}
                >
                    <Settings />
                </button>
            </div>
        );

        const mobileFilter = ( // TODO: Remove content of <Fragment> the mobile code after updating to version 2
            <>
                <Accordion
                    className="ltn-de-producers-page-accordion"
                    collapses={[
                        {
                            label: (
                                <span className="ltn-de-producers-order-by-label">
                                    {pageContent.tariffFormMobileLabel}
                                </span>
                            ),
                            children: this.renderTariffCalculationForm({
                                className: 'ltn-de-producers-page-controls-tariff-calculator'
                            }),
                            id: 1
                        },
                        {
                            label: (
                                <span className="ltn-de-producers-order-by-label">
                                    {pageContent.sortingLabel}
                                    {' '}
                                    <b>{PRODUCER_ORDER_BY_OPTIONS[orderBy]}</b>
                                </span>
                            ),
                            children: this.renderOrderList(),
                            id: 2
                        }
                    ]}
                />
                <div className="ltn-de-producers-filter-mobile">{this.renderProducerFilter()}</div>
                <div className="ltn-de-producers-asc-desc-mobile">{this.renderAscDescArrow()}</div>
            </>
        );

        return (
            <>
                {isVersion2Mode ? mobileFilterV2 : mobileFilter}
                <section className="ltn-de-producers-filter-section">
                    <div className="ltn-de-producers-filter-wrapper">
                        {isVersion2Mode ? (
                            // TODO: Remove the condition and use both values when we will have the ability
                            <>
                                <ProducerFilterInfo
                                    electricityConsumption={
                                        energyType === ENERGY_TYPES.ELECTRICITY ? consumption : undefined
                                    }
                                    gasConsumption={energyType === ENERGY_TYPES.GAS ? consumption : undefined}
                                    zip={zip}
                                    onChangeButtonClick={() => {
                                        this.setState({ tariffCalculatorPopupIsOpen: true });
                                    }}
                                />
                                {this.renderProducerSortFilter({
                                    className: 'ltn-de-producers-order-by-version2',
                                    labels: { header: 'Sortierung:' },
                                    withAscDescOptions: false,
                                    orderButtonIcon: <Triangle />,
                                    orderByOptions: {
                                        price: PRODUCER_ORDER_BY_OPTIONS.price,
                                        distance: PRODUCER_ORDER_BY_OPTIONS.distance
                                    }
                                })}
                            </>
                        ) : (
                            this.renderProducerSortFilter({
                                labels: {
                                    header: pageContent.sortingLabel,
                                    orderAsc: pageContent.orderAscLabel,
                                    orderDesc: pageContent.orderDescLabel
                                },
                                orderByOptions: PRODUCER_ORDER_BY_OPTIONS
                            })
                        )}
                        {this.renderProducerFilter()}
                        {isVersion2Mode ? this.renderMapSwitcher() : null}
                    </div>
                </section>
            </>
        );
    }

    renderPageContent() {
        const {
            content: { landingDetails },
            producers,
            query: { energyType = DEFAULT_ENERGY_TYPE }
        } = this.props;

        const { selectedProducerTypes, isMapOpen } = this.state;

        const { isAppGoogleScriptLoaded } = this.context;

        return (
            <section className="ltn-de-producers-section">
                <div className="ltn-de-producers-section__container">
                    {isMapOpen && isAppGoogleScriptLoaded ? (
                        <div className="ltn-de-producers-page-map">
                            <ProducersMap
                                producers={filterProducersByType(producers, selectedProducerTypes)}
                                onProducerDetailsClick={producer => {
                                    if (window.self !== window.top) {
                                        window.parent.postMessage({ producer }, '*');
                                    }
                                    GTM.addProductDetailsClickDataToDL(
                                        // FIXME: Need think about cases when user click this by keyboard (Enter)
                                        // or mouse wheel (GTM)
                                        producer,
                                        landingDetails,
                                        energyType
                                    );
                                }}
                                styles={
                                    {
                                        mobileHeight: 'calc(var(--frame-height, 100vh) * 0.7)',
                                        desktopHeight: 'calc(var(--frame-height, 100vh) * 0.9)'
                                    }
                                }
                            />
                        </div>
                    ) : null}
                    <ul className="ltn-de-producers-list">{this.renderProducers()}</ul>
                </div>
            </section>
        );
    }

    render() {
        const {
            content: {
                pageContent,
                isVersion2Mode,
                defaultFooterContent,
                landingContent,
                shouldOpenTariffCalculatorByDefault
            },
            query: { orderBy = DEFAULT_ORDER_BY },
            asPath
        } = this.props;

        const { selectedProducerTypes, tariffCalculatorPopupIsOpen, compareProductServerErrorDetails } = this.state;

        const producerPageClassName = classNames(
            'ltn-de-producers-page',
            tariffCalculatorPopupIsOpen && 'ltn-de-producers-page-popup-open'
        );
        return (
            <div className={producerPageClassName}>
                {this.renderToast({
                    key: 'compareProductServerErrorDetails',
                    value: compareProductServerErrorDetails
                })}
                <MainContainer
                    content={{
                        footerContent: landingContent?.footer?.fields || defaultFooterContent,
                        tariffCalculationForm: landingContent?.tariffCalculationForm,
                        headerContent: landingContent?.header,
                        isPageInIframe: true
                    }}
                    showHeader={landingContent?.showStaticHeader}
                    showFooter={landingContent?.showStaticFooter}
                    pagePath={asPath}
                >
                    <TariffCalculatorPopup
                        isOpen={tariffCalculatorPopupIsOpen}
                        onPopupClose={() => this.setState({ tariffCalculatorPopupIsOpen: false })}
                        onProducerTypeChange={this.onProducerTypeChange}
                        producerFileterOptions={PRODUCER_FILTER_OPTIONS}
                        selectedProducerTypes={selectedProducerTypes}
                        filterCaption={FILTER_CAPTION}
                        onSelectOrderByOption={this.selectOrderByOption}
                        orderBy={orderBy}
                        shouldOpenTariffCalculatorByDefault={shouldOpenTariffCalculatorByDefault}
                    >
                        {this.renderTariffCalculationForm()}
                    </TariffCalculatorPopup>
                    <div className="ltn-de-producers-page-header">
                        <div className="ltn-de-producers-page-header-content">
                            <h1>{pageContent.title}</h1>
                            <div
                                className="ltn-de-producers-bioenergy-text"
                                dangerouslySetInnerHTML={{
                                    __html: richTextToHtml(pageContent.bioenergyText.content)
                                }}
                            />
                        </div>
                    </div>
                    {isVersion2Mode ? null : (
                        <div className="ltn-de-producers-page-controls">
                            {this.renderTariffCalculationForm({
                                className: 'ltn-de-producers-page-controls-tariff-calculator'
                            })}
                        </div>
                    )}
                    {this.renderFilterSection()}
                    {this.renderPageContent()}
                </MainContainer>
                {this.renderProducerTariffModal()}
                {this.renderLoader()}
            </div>
        );
    }
}

ProducersPage.propTypes = {
    content: PropTypes.shape({
        pageContent: PropTypes.shape({
            bioenergyText: PropTypes.shape({
                content: PropTypes.arrayOf(PropTypes.shape({}))
            }),

            title: PropTypes.string,
            tariffFormMobileLabel: PropTypes.string,
            sortingLabel: PropTypes.string,
            orderDescLabel: PropTypes.string,
            orderAscLabel: PropTypes.string
        }),

        isVersion2Mode: PropTypes.bool,
        isPageInIframe: PropTypes.bool,
        defaultFooterContent: PropTypes.shape({}),
        shouldOpenTariffCalculatorByDefault: PropTypes.bool,
        landingContent: PropTypes.shape({
            tariffCalculationForm: PropTypes.shape({}),
            showStaticHeader: PropTypes.bool,
            showStaticFooter: PropTypes.bool,
            header: PropTypes.shape({}),
            footer: PropTypes.shape({
                fields: PropTypes.shape({})
            })
        }),

        landingDetails: PropTypes.shape({})
    }),

    producers: PropTypes.arrayOf(PropTypes.shape({})),
    query: PropTypes.shape({
        identifier: PropTypes.string,
        orderBy: PropTypes.string,
        filterBy: PropTypes.string,
        energyType: PropTypes.string,
        zip: PropTypes.string,
        consumption: PropTypes.string,
        ascDesc: PropTypes.string
    }),

    isEcoenergy: PropTypes.bool,
    asPath: PropTypes.string,
    commonServerErrorDetails: PropTypes.shape({}),
    compareProductServerErrorDetails: PropTypes.shape({}),
    compareProductPostcodeErrorMessage: PropTypes.string,
    consumptionNoTariffErrorMessage: PropTypes.string
};

ProducersPage.defaultProps = {
    content: {
        pageContent: {}
    },

    query: {
        energyType: DEFAULT_ENERGY_TYPE,
        filterBy: DEFAULT_FILTER_BY
    },

    producers: [],
    isEcoenergy: false,
    asPath: undefined,
    commonServerErrorDetails: undefined,
    compareProductPostcodeErrorMessage: undefined,
    consumptionNoTariffErrorMessage: undefined,
    compareProductServerErrorDetails: undefined
};

ProducersPage.getInitialProps = async function (ctx) {
    const ctxWithDefaultQueryParams = { ...ctx, query: pickQueryParamsWithDefaults(ctx.query) };

    return CommonPageHelpers.getInitialPagePropsCustom(
        ctxWithDefaultQueryParams,
        ProducersPageHelpers.getProducerPageContent,
        ProducersPageHelpers.getProducerPageData
    );
};

export default ProducersPage;
